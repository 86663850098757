@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.custom-img {
  background-image: url('/public/Joshua-Blackmore.jpg');
}

html {
  font-family: 'Source Code Pro', sans-serif;
}

.player {
  position: relative;
  padding-top: 56.25%;
  
}
.player > div {
 position: absolute;
 top: 0;
 
}